import React, {useState, useEffect} from "react";
import Banner from "../components/Banner";
import HeaderText from "../components/HeaderText";
import LargeImage from "../components/LargeImage";
import TextContentWithHeader from "../components/TextContentWithHeader";
import XMPButton from "../components/XMPButton";
import DescriptionText from "../components/DescriptionText";
import TopContributors from '../components/visualizations/TopContributors';

export default function Home() {

  // fetch accounts data
  let [accounts, setAccounts] = useState([])
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + `/db/get/accounts`)
    // fetch(`http://info5900.infosci.cornell.edu:8080/db/get/accounts/db/get/accounts`)
    .then((value) => {
        return value.json()
    })
    .then((fetchedData) => {
      fetchedData.sort((a, b) => a['id'] > b['id'])
      setAccounts(fetchedData)
    })
  }, [])

  return (
    <div>
      <Banner
        text="Challenging discrimination with data."
        header="The Xenophobia Meter"
      />
      <div className="flex flex-col items-center md:py-12">
        <div className="w-[80%]">
          <TextContentWithHeader
            text="The Xenophobia Meter Project (XMP) informs researchers, government and non-governmental organizations, and the general public about levels and changes in xenophobic speech on Twitter.

            Our mission is to combat xenophobia by promoting awareness, understanding, and accountability. By providing sources, reports, and shareable data, we aim to hold governments and organizations accountable for their role in perpetuating xenophobia by identifying and labeling xenophobic speech, spurring reform through the spotlighting of wrongdoing."
            header="The Xenophobia Meter"
          />
          <div className="mb-12">
            <DescriptionText text="The Xenophobia Meter Project was launched with seed awards from Global Cornell’s Mario Einaudi Center for International Studies and Migrations initiative, with support from a Just Futures partnership with the Andrew W. Mellon Foundation." />
          </div>

          <XMPButton text="Learn more"  redirect={'/about'} />
        </div>

        <div className="mt-[120px]" />
          <div className="bg-xeno-lightyellow w-full py-36 flex justify-center">
            <div className="max-lg:w-full">
              <HeaderText header="Exploring Xenophobic Content Through Data" />
                <div className='flex justify-between max-lg:w-full'>
                  <TopContributors accounts={accounts} />
                </div>

              <XMPButton text="View all Data" redirect={'/data'} />
            </div>
          </div>
        <div className="mt-8 md:mt-[120px] w-full md:w-[60%] flex justify-center">
          <div className="flex flex-col">
            <div className="flex justify-center">
              <DescriptionText
                header="Xenophobia"
                text="An intense fear or dislike of people who are perceived to be foreign or from a different culture, nation, or ethnic group. It is a form of prejudice and discrimination that can lead to hostility, hatred, and even violence toward those who are seen as outsiders. It is important to recognize and challenge xenophobic attitudes and behaviors in order to promote greater understanding, tolerance, and acceptance among different cultures and peoples."
              />
            </div>

            <div className="flex flex-col md:flex-row justify-between w-full mt-8 gap-8">
              <DescriptionText
                header="Calibration"
                text="A procedure for discussing disagreement between multiple labelers on the label of a tweet."
                textLeft
              />
              <DescriptionText
                header="Labeler"
                text="A person who participates in labeling tweets."
                textLeft
              />
              <DescriptionText
                header="Labeling"
                text="Assigning ratings, categories, and descriptions to tweets about the level of xenophobia using a set of guidelines. "
                textLeft
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
