import React from 'react'

export default function BlockQuote(props) {
  return (
    <div className='my-12 md:my-12 bg-slate-100 px-8 pt-20 rounded-sm text-left' style={{width:props.width}}>
        <p className='text-lg'>“{props.quoteText}”</p>
        <p className='italic mt-12 w-full text-right pb-8'>~ {!props.author ? "Anonymous labeler" : props.author}</p>
    </div>
  )
}
