import React from "react";

export default function Footer() {
  return (
    <div className="absolute bottom-[-100px] md:bottom-[-30px] left-0 right-0 w-full  h-[250px] bg-footer-grey flex flex-col-reverse md:flex-row items-center justify-between text-white p-8 md:px-36">
      <img src="footer.png" alt="footer" className="h-[60px] md:h-[100px]" />

      <div className="flex flex-col items-start">
        <h2 className="text-lg md:text-2xl">Email: xyz@gmail.com</h2>
        <h2 className="text-lg md:text-2xl">Phone: (917)-XXX-XXX</h2>
        <h2 className="text-lg md:text-2xl">FAX: XXX-XXX-XXX</h2>
      </div>
    </div>
  );
}
