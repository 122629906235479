import React from "react";
import Banner from "../components/Banner";
import BlockQuote from "../components/BlockQuote";
import DescriptionText from "../components/DescriptionText";
import LargeImage from "../components/LargeImage";
import TextContentWithHeader from "../components/TextContentWithHeader";
import HeaderText from "../components/HeaderText";

export default function Usage() {
  return (
    <div className="">
      <Banner text="Try out our meter yourself" header="Use Our Meter" />
      <div className="flex flex-col items-center md:px-24 py-24">
        <HeaderText header="Background and Development" />
        <div className="bg-xeno-lightyellow  mt-12">
          <DescriptionText
            text="The Xenophobia Meter is an innovative tool designed to help quantify and understand the prevalence of xenophobia in social media. Developed over three years in collaboration with a team of experts in migration law and policy, human rights, and AI, the Xenophobia Meter analyzes attitudes towards foreigners based on their perceived or actual citizenship or status. In creating the meter, we examine xenophobia as a unique phenomenon that is related to but distinct from other forms of hate speech related to race, gender, religion, etc. The Xenophobia meter was developed in the context of Twitter speech, but we believe that it can be useful for evaluating other content in other social media platforms, news articles, memes, imagery, videos, and ads."
            header="Background and Development"
            noBackground
          />
          <DescriptionText
            text="The meter helps to train labelers to understand the nuances of xenophobia and what it may look like and increases efficiency of calibration by fast forwarding labeler discussions to labelers knowledge and assumptions. The result is highly nuanced encoding that may give more flexibility to future researchers who use the dataset and facilitate correlations.  Through our Xenophobia Meter, researchers and advocates  can gain a deeper understanding of attitudes towards foreigners online and take steps toward creating a safer and welcoming space for all."
            padding="px-12 py-8"
            noBackground
          />
        </div>
      </div>

      <div className="bg-xeno-lightyellow w-full p-8 md:py-32  flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center md:max-w-[1200px]">
          <div className="mb-8">
            <HeaderText header="How the Scale Works" />
          </div>
          <DescriptionText
            text="The Xenophobia Meter scale includes 7 levels of attitudes toward foreigners, from extremely xenophobic to extremely pro-foreigner. Each level can be described by one or more reasoning criteria. Researchers and advocates can use these nuanced but concrete categories to evaluate speech. "
            padding="px-12 py-8"
          />
          <DescriptionText
            text="To use the meter on a dataset, start by defining the distinct pieces of content within the dataset to analyze: sentences, posts, images, or other units of analysis. For each piece, examine its content and decide the degree to which it fits any of the reasoning criteria outlined in the meter. Once you find a reasoning criterion that matches the content, assign to that content piece with the corresponding level."
            padding="px-12 py-0"
          />
          <DescriptionText
            text="
          Learning to use the meter and to apply it on a dataset requires training. Over time, human labelers become better at using the meter and labeling content. Learn more about the labeling procedures. "
            padding="px-12 py-8"
          />
          <DescriptionText
            text="
          The Meter is freely available to the public to use and modify for your own needs, language,
          country, and online content.  We welcome proposals to collaborate with translating the Meter to other languages and modify it to be relevant in other contexts. Please cite the Xenophobia Meter as Cornell University Xenophobia Meter, LINK."
            padding="px-12 py-0"
            margin="mb-24"
          />
          <table className="bg-white p-4 max-w-[1200px]">
            <tr>
              <th className="p-8 border-2">Value</th>
              <th className="p-8 border-2">Reasoning</th>
            </tr>
            {[1, 2, 3, 4, 5].map((ele) => {
              return (
                <tr>
                  <td className="md:p-8 border-2">(-3) Very Xenophobic</td>
                  <td className="p-8 border-2">
                    <ul className="text-left ">
                      {[
                        "Calls for violent/hurtful actions or policies",
                        "Uses xenophobic slurs",
                        "Calls for actions that clearly disadvantage immigrants",
                        "Xenophobic hashtags that call for action or use a verb",
                      ].map((ele) => {
                        return <li className="list-disc">{ele}</li>;
                      })}
                    </ul>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      <div className="mt-24">
        <HeaderText header="Examples of Labeled Tweets" />
      </div>
      <div className="flex flex-col items-center md:px-24 md:py-12">
        <div className="md:w-60%">
          {[
            {
              comment:
                "We must remain calm but not complacent about the Chinese Coronavirus. We addressed it on tonight's show. We hope you'll watch if you missed it.",
              author: "@TuckerCarlson",
              rating: "-3",
              reason: "The Chinese Coronavirus is a xenophobic slur ",
            },
            {
              comment:
                "We must remain calm but not complacent about the Chinese Coronavirus. We addressed it on tonight's show. We hope you'll watch if you missed it.",
              author: "@TuckerCarlson",
              rating: "-3",
              reason: "The Chinese Coronavirus is a xenophobic slur ",
            },
            {
              comment:
                "We must remain calm but not complacent about the Chinese Coronavirus. We addressed it on tonight's show. We hope you'll watch if you missed it.",
              author: "@TuckerCarlson",
              rating: "-3",
              reason: "The Chinese Coronavirus is a xenophobic slur ",
            },
          ].map((ele) => {
            return (
              <div className="w-full flex flex-col items-start px-8 py-2">
                <BlockQuote quoteText={ele.comment} author={ele.author} />
                <div className="flex flex-col justify-between h-[40px] md:h-[80px]">
                  <p className="text-md text-left">
                    <span className="font-bold">Rating: </span>
                    {ele.rating}
                  </p>
                  <p className="text-md text-left">
                    <span className="font-bold">Reasoning: </span> {ele.reason}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
