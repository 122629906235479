import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './view/Home';
import Labelling from './view/Labelling';
import DataPage from './view/DataPage';
import Team from './view/Team';
import Footer from './components/Footer';
import About from './view/About';
import Usage from './view/Usage';

function App() {
  return (
    <div className="App relative">
      <Navbar />
      <div className='pb-[220px]'>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/label" element={<Labelling />} />
          <Route exact path="/data" element={<DataPage />} />
          <Route path="/usage" element={<Usage />} />
          <Route exact path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
