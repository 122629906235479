import React from 'react';
import YouTube from 'react-youtube';

function YoutubeVideo({ videoId }) {

  return (
    <div className='w-full overflow-scroll my-12'>
      <YouTube videoId={videoId} />
    </div>

  );
}

export default YoutubeVideo;
