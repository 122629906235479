import React from "react";

export default function DescriptionText(props) {
  return (
    <div
      className={`${props.textLeft ? 'text-left' : props.textRight ? 'text-right' : 'text-left'} text-lg md:rounded-xl ${
        props.noBackground ? "" : "bg-xeno-lightyellow"
      } ${props.padding ? props.padding : 'p-12'} ${props.margin ? props.margin : 'm-0'}`}
    >
      {props.header ? <h2 className='text-2xl my-2 font-semibold'>{props.header}</h2> : <></>}
      <p>
        {props.text}
      </p>
    </div>
  );
}
