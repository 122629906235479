import { useEffect, useState } from "react"
import * as d3 from 'd3'

const PublicResponseVis = ({tweets, accounts}) => {

  const [data, setData] = useState([])
  const refreshData = () => {
    // select random accounts to grab tweets from
    const queryAccounts = [...accounts].sort(() => Math.random() < 0.5).slice(0, 5) 
    // query tweets for visualization
    fetch(
      process.env.REACT_APP_API_URL + `/db/get/tweets?labeled&num=10000&account_ids=${
        queryAccounts.map(account => account['id'])
      }`
    )
    .then((value) => {
        return value.json()
    })
    .then((fetchedData) => {
        const newData = fetchedData.sort(() => Math.random() < 0.5).slice(0, numTweets)
        // attach a field for index
        newData.forEach((v, i) => v["idx"] = i)
        setData(newData)
    })
  }
  useEffect(() => refreshData(), [tweets])

  const accountTypeToColor = {
    "1": "lightblue",
    "2": "orange",
    "3": "purple",
    "4": "pink"
  }

  const numTweets = 10
  
  useEffect(() => {
    const tweetsPerRow = 4
    const paddingOffsets = 1
  
    const tweetOffsetX = 100 / (tweetsPerRow + paddingOffsets) // unit: %
    const tweetOffsetY = 100 / (numTweets / tweetsPerRow + paddingOffsets) // unit: %
    const svg = d3.select("#publicResponseVis")
      // .attr("width", 1000)
      // .attr("height", 700)
    // define scale for favorites param
    const faveMin = Math.min(...data.map(d => d["favorite_count"]))
    const faveMax = Math.max(...data.map(d => d["favorite_count"]))
    const faveScale = d3.scaleLinear()
      .domain([faveMin, faveMax])
      .range([tweetOffsetX * 0.05, tweetOffsetX * 0.5]) 
    // define scale for color of dots based on xenophobia
    const colorScale = d3.scaleLinear()
      .domain([-3, -0.1, 0, 0.1, 3])
      .range(["red", "darkred", "blue", "darkgreen", "green"])
    // clear previous visualization
    svg.selectAll("*").remove()
    // map tweets to circles
    svg.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
        .attr("cx", (d) => (d["idx"] % tweetsPerRow 
          + 0.8 + (Math.random() / 2 - 0.25)) 
          * tweetOffsetX + "%"
        )
        .attr("cy", (d) => (Math.floor(d["idx"] / tweetsPerRow) 
          + 0.8 + (Math.random() / 2 - 0.25)) 
          * tweetOffsetY + "%"
        )
        .attr("idx", (d) => d["idx"])
        .style("fill", (d) => colorScale(d["average_rating"]))
        // animate the radius
        .transition()
        .duration(500)
        .attr("r", (d) => faveScale(d["favorite_count"]) + "%")
    // create a tooltip
    const tooltip = d3.select("#prVisTooltip")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("max-width", "15rem")
      .style("pointer-events", "none")
      .style("background-color", "white")
      .style("border", "2px solid black")
      .style("padding", "0.5rem")
      .html("<p>Test tooltip</p>")
    svg.selectAll("circle")
      .on("mouseover", (e) => {
        const idx = d3.select(e.target).attr("idx")
        const accountTypeColor = accountTypeToColor[accounts[data[idx]["account_id"] - 1]["account_type_id"]]
        tooltip
          .style("visibility", "visible")
          .style("border-color", accountTypeColor)
        tooltip.html(
          `<p style="font-size: 1rem">
            <img style="width: 3rem; border-radius: 1.75rem; border: 3px solid ${accountTypeColor}" src="/images/account-images/${data[idx]["account_id"]}.png"/>
            @${accounts[data[idx]["account_id"] - 1]["name"]}
          </p>
          <quote>
            ${data[idx]["content"]}
          </quote>
          <p>
          Likes: ${data[idx]["favorite_count"]}
          </p>
          <p>
          Xenophobia Rating: ${data[idx]["average_rating"]}
          </p>
          `
        )
        d3.select(e.target)
          .style("stroke", accountTypeColor)
          .style("stroke-width", "2")
      })
      .on("mousemove", (e) => {
        const tooltipOffset = 15; // in px
        tooltip.style("left", `${e.pageX + tooltipOffset}px`)
        tooltip.style("top", `${e.pageY + tooltipOffset}px`)
      })
      .on("mouseout", (e) => {
        tooltip.style("visibility", "hidden")
        d3.select(e.target)
          .style("stroke", "none")
          .style("stroke-width", "0")
      })
    svg.exit().remove()
  }, [data])

  return <div 
    id="prVisSection"
    style={{
      margin: '1rem auto',
      textAlign: 'left',
      maxWidth: '100%',
      overflow: 'hidden'
    }}
    className="max-xl:p-4"
    >
    <h2 className="text-3xl my-4">What is the public response to xenophobic content?</h2>
    <p className="my-4">Below we have displayed a few tweets that fall across our xenophobic scale to see which ones are the most popular (measured by the count of favorites by other users).</p>
    <div className="flex max-xl:flex-col">
      <svg id='publicResponseVis' style={{ overflow: "scroll", flexGrow: "1", minHeight: "400px"}}></svg>
      <aside className='p-4 max-xl:flex justify-between' style={{minWidth: '16rem'}}>
        <div>
          <p className="mt-6 font-bold">Number of Likes:</p>
          <p>
            <span className='font-bold text-2xl'>{Math.min(...data.map(d => d["favorite_count"]))}</span>
            <i className="my-2 mx-2 inline-block border-2 border-black translate-y-3" style={{width: '1rem', height: '1rem', borderRadius: '1rem'}}></i>
            <i className="my-2 mx-2 inline-block border-2 border-black translate-y-3" style={{width: '1.5rem', height: '1.5rem', borderRadius: '1.5rem'}}></i>
            <i className="my-2 mx-2 inline-block border-2 border-black translate-y-3" style={{width: '2rem', height: '2rem', borderRadius: '2rem'}}></i>
            <span className='font-bold text-2xl'>{Math.max(...data.map(d => d["favorite_count"]))}</span>
          </p>
          <p className="mt-6 font-bold">Tweet Rating:</p>
          <ul style={{listStyle: "none"}}>
            <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "red"}}></i> Anti-Foreigner</li>
            <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "blue"}}></i> Neutral</li>
            <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "green"}}></i> Pro-Foreigner</li>
          </ul>
        </div>
        <div>
          <p className="mt-6 font-bold">Type of Account:</p>
          <ul style={{listStyle: "none"}}>
            <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{border: "2px solid orange"}}></i> Media</li>
            <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{border: "2px solid purple"}}></i> Non-Profit</li>
            <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{border: "2px solid lightblue"}}></i> Governmental</li>
            {/* <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{border: "2px solid pink"}}></i> Other</li> */}
          </ul>
          <button className="mt-6 text-white px-4 py-2 rounded-3xl my-4 bg-sky-500 hover:bg-sky-700" onClick={refreshData}>Refresh Tweets</button>
        </div>
      </aside>
    </div>
    <div id="prVisTooltip"></div>
  </div>
}

export default PublicResponseVis