import React, { useEffect, useState } from 'react'

export default function LargeImage(props) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className='flex flex-col items-center w-full md:w-auto'>
      {props.src ? <img src={props.src} style={dimensions.width < 420 ? {width:'100%',height:'300px'} : {width:props.width,height:props.height}} onError={props.onError} /> : <div className={`bg-gray-200 text-white flex items-center justify-center `} style={dimensions.width < 420 ? {width:'100%',height:'300px'} : {width:props.width,height:props.height}}>
        <p className=' text-5xl'> LARGE IMAGE</p>
      </div>}

      {props.caption && <p style={dimensions.width < 420 ? {width:'100%'} : {width:props.width}} className='text-sm text-center p-2 mb-8 '>{props.caption ? props.caption : "DataViz description DataViz description DataViz description DataViz description DataViz description "}</p>}
    </div>


  )
}
