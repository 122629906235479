import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import HeaderText from "../components/HeaderText";
import LargeImage from "../components/LargeImage";
import TextContentWithHeader from "../components/TextContentWithHeader";
import XMPButton from "../components/XMPButton";
import MainContributor from "../components/MainContributor";

const importantPeople = [
  {
    name: "Beth Lyon",
    title: "Co-founder",
    pronouns: "she/her",
    caption: "Beth Lyon",
    src: "/images/project-member-headshots/6.jpg",
  },
  {
    name: "Bao Kham Chau",
    title: "Cofounder/Director",
    pronouns: "he/him/his",
    caption: "Bao Kham Chau",
    src: "/images/project-member-headshots/7.jpg",
  },
  {
    name: "Lily Pagan",
    title: "Co-Founder, Advisor, UI Engineer",
    caption: "Lily Pagan",
    pronouns: "she/her",
    src: "/images/project-member-headshots/unknown.png",
  },
  {
    name: "Gilly Leshed",
    title: "Collaborator",
    caption: "Gilly Leshed",
    pronouns: "she/her",
    src: "/images/project-member-headshots/1.jpg",
  },
];

export default function Team() {
  // fetch project member data on load
  const [projectMemberData, setProjectMemberData] = useState([]);
  useEffect(() => {
    if (projectMemberData.length === 0) {
      fetch(process.env.REACT_APP_API_URL + "/db/get/project_members")
        .then((value) => {
          return value.json();
        })
        .then((fetchedData) => {
          setProjectMemberData(fetchedData.sort((a, b) => a["id"] > b["id"]));
          console.log(fetchedData);
        });
    }
  }, [projectMemberData.length]);

  const handleError = (e) => {
    e.target.src = "/images/project-member-headshots/unknown.png"; // Set the default image on error
    e.target.onError = null; // Prevent infinite loop in case the default image is also unavailable
  };

  return (
    <div>
      <Banner header="Our Team" text="Learn about the team behind XMP" />
      <div className="flex flex-col items-center md:px-24 md:py-12">
        {importantPeople.map((person, ind) => {
          return <MainContributor reversed={ind % 2 === 0} name={person.name} title={person.title} pronouns={person.pronouns} caption={person.name} src={person.src} text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />;
        })}
        <HeaderText header="All Members" />
        <div className="grid grid-cols-1 px-12 md:grid-cols-4 lg:grid-cols-5 w-full md:mt-8">
          {projectMemberData.map((member) => {
            const displayName =
              (member["preferred_name"] !== ""
                ? member["preferred_name"]
                : member["first_name"]) +
              " " +
              member["last_name"];
            return (
              <LargeImage
                src={`/images/project-member-headshots/${member["id"]}.jpg`}
                width="200px"
                height="200px"
                alt={displayName}
                caption={displayName}
                onError={handleError}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
