import React from "react";
import LargeImage from "./LargeImage";
import HeaderText from "./HeaderText";
import TextContentWithHeader from "./TextContentWithHeader";

function MainContributor(props) {
  return (
    <div
      className={`flex flex-col ${props.reversed ? "md:flex-row-reverse" : "md:flex-row"
        } justify-around w-full md:gap-24 p-8 md:p-0`}
    >
      <LargeImage
        width="400px"
        height="400px"
        caption={props.caption}
        src={props.src}
      />
      <div className="flex flex-col w-full">

        <HeaderText header={props.name} textLeft />

        <HeaderText header={props.pronouns} size="text-xl" weight="font-normal" textLeft />


        <TextContentWithHeader
          textLeft
          padding="p-0"
          header={props.title}
          text={props.text}
          noBackground
        />

      </div>
    </div>
  );
}

export default MainContributor;
