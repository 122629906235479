import { useEffect, useState } from "react"
import * as d3 from 'd3'

const XenophobiaByType = ({accounts}) => {

  const [data, setData] = useState([])
  const refreshData = () => {
    setData(accounts)
  }
  useEffect(() => refreshData(), [accounts])

  useEffect(() => {
    const width = 800
    const height = 600
    const padding = 80
    const paddingLeft = 130
    const paddingBottom = 110
    const maxCircleRadius = 30
  
    const svg = d3.select("#xenophobiaByTypeVis")
      .attr("width", width)
      .attr("height", height)

    // define scale for color of account based on type
    const colorScale = d3.scaleLinear()
      .domain([1, 2, 3, 4])
      .range(["lightblue", "orange", "purple", "pink"])
    // define scale for y of account based on type
    const accountTypeScale = d3.scaleLinear()
      .domain([1, 3])
      .range([padding, height - padding - paddingBottom])
    // define x scale for xenophobia rating
    const xenophobiaScale = d3.scaleLinear()
      .domain([-3, 3])
      .range([padding + paddingLeft, width - padding])
    // define r scale for num labeled
    const numLabledScale = d3.scaleLinear()
      .domain([500, 700])
      .range([5, maxCircleRadius])

    // clear previous visualization
    svg.selectAll("*").remove()

    // create manual axis labels
    const accountTypes = ["Governmental", "Media", "Non-Profit"]
    accountTypes.forEach((type, idx) => {
        svg.append("text")
            .attr("x", padding / 4)
            .attr("y", _ => accountTypeScale(idx + 1))
            .attr("width", "30px")
            .text(type)
    })
    const ratingLabels = ["Anti-Foreigner", "Neutral", "Pro-Foreigner"]
    svg.append("line")
      .attr("x1", xenophobiaScale(-3) - padding)
      .attr("y1", height - (padding * 1.25))
      .attr("x2", xenophobiaScale(3) + padding)
      .attr("y2", height - (padding * 1.25))
      .attr("stroke", "black")
    ratingLabels.forEach((label, idx) => {
      const ratingIdxToXenophobia = d3.scaleLinear()
        .domain([0, 1, 2])
        .range([-3, 0, 3])
      svg.append("text")
        .attr("y", height - padding)
        .attr("x", xenophobiaScale(ratingIdxToXenophobia(idx)))
        .attr("transform", "translate(-50,0)")
        .text(label)
    })
    svg.append("text")
      .attr("width", height)
      .attr("y", height - padding / 2)
      .attr("x", paddingLeft + (width - paddingLeft) / 2)
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .text("Average rating of labeled tweets")

    // generate the random y offsets for accounts
    const yOffsets = [...Array(data.length)].map((_) => + Math.random() * 80 - 40)
    // map accounts to circles
    svg.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => xenophobiaScale(d["average_rating"]))
      .attr("cy", (d, i) => accountTypeScale(d["account_type_id"]) + yOffsets[i])
      .attr("r", (d) => numLabledScale(d["num_rated_tweets"]))
      .attr("fill", (d) => colorScale(d["account_type_id"]))
    // append account images atop circles
    svg.selectAll("image")
      .data(data)
      .enter()
      .append("image")
        .attr("idx", (d) => d['id'] - 1)
        .attr("x", (d) => xenophobiaScale(d["average_rating"]) - numLabledScale(d["num_rated_tweets"]))
        .attr("y", (d, i) => accountTypeScale(d["account_type_id"]) - numLabledScale(d["num_rated_tweets"]) + yOffsets[i])
        .attr("href", (d) => `/images/account-images/${d["id"]}.png`)
        .attr("width", (d) => numLabledScale(d["num_rated_tweets"]) * 2)
        .attr("opacity", 0.25)
        .attr("clip-path", "inset(0% round 50%)")
    // set up tooltip
    const tooltip = d3.select("#xbtVisTooltip")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("max-width", "15rem")
      .style("pointer-events", "none")
      .style("background-color", "white")
      .style("border", "2px solid black")
      .style("padding", "0.5rem")
      .html("<p>Test tooltip</p>")
    svg.selectAll("image")
      .on("mouseover", (e) => {
        const idx = d3.select(e.target).attr("idx")
        tooltip.style("visibility", "visible")
        tooltip.html(
          `<p style="font-size: 1rem">
            @${accounts[idx]["name"]}
          </p>
          <p>
          Avg. Rating: ${accounts[idx]["average_rating"]}
          </p>
          <p>
          Num. Labeled Tweets: ${accounts[idx]["num_rated_tweets"]}
          </p>
          `
        )
        d3.select(e.target)
          .style("stroke", 'black')
          .style("stroke-width", "2")
      })
      .on("mousemove", (e) => {
        const tooltipOffset = 15; // in px
        tooltip.style("left", `${e.pageX + tooltipOffset}px`)
        tooltip.style("top", `${e.pageY + tooltipOffset}px`)
      })
      .on("mouseout", (e) => {
        tooltip.style("visibility", "hidden")
        d3.select(e.target)
          .style("stroke", "none")
          .style("stroke-width", "0")
      })
    svg.exit().remove()
  }, [data])

  return <div 
    id="xbtVisSection"
    style={{
      margin: '1rem auto',
      textAlign: 'left',
      maxWidth: '100%',
      overflow: 'hidden'
    }}
    className="p-4"
    >
    <h2 className="text-3xl my-4">Is Twitter a Safe Space for Foreigners?</h2>
    <p className="my-2">Discover what types of accounts are promoting anti-foreigner content, and which are promoting pro-foreigner content.</p>
    <div className="flex max-xl:flex-col max-xl:overflow-x-scroll">
      <svg id='xenophobiaByTypeVis' style={{ overflow: "scroll", marginLeft: "50px", marginTop: "25px"}}></svg>
      <aside className='p-4 max-xl:flex justify-between' style={{minWidth: '16rem'}}>
        <div>
          <p className="mt-6 font-bold">Number of Labeled Tweets:</p>
          <p>
            <span className='font-bold text-2xl'>{Math.min(...data.map(d => d["num_rated_tweets"]))}</span>
            <i className="my-2 mx-2 inline-block border-2 border-black translate-y-3" style={{width: '1rem', height: '1rem', borderRadius: '1rem'}}></i>
            <i className="my-2 mx-2 inline-block border-2 border-black translate-y-3" style={{width: '1.5rem', height: '1.5rem', borderRadius: '1.5rem'}}></i>
            <i className="my-2 mx-2 inline-block border-2 border-black translate-y-3" style={{width: '2rem', height: '2rem', borderRadius: '2rem'}}></i>
            <span className='font-bold text-2xl'>{Math.max(...data.map(d => d["num_rated_tweets"]))}</span>
          </p>
        </div>
        <div>
          <p className="mt-6 font-bold">Type of Account:</p>
          <ul style={{listStyle: "none"}}>
            <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "orange"}}></i> Media</li>
            <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "purple"}}></i> Non-Profit</li>
            <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "lightblue"}}></i> Governmental</li>
            {/* <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "pink"}}></i> Other</li> */}
          </ul>
        </div>
      </aside>
    </div>
    <div id="xbtVisTooltip"></div>
  </div>
}

export default XenophobiaByType