import React from "react";
import Banner from "../components/Banner";
import HeaderText from "../components/HeaderText";
import LargeImage from "../components/LargeImage";
import TextContentWithHeader from "../components/TextContentWithHeader";
import XMPButton from "../components/XMPButton";
import DescriptionText from "../components/DescriptionText";
import YoutubeVideo from "../components/VideoComponent";

export default function About() {
  return (
    <div>
      <Banner
        text="Learn more about our mission and background."
        header="About Us"
      />
      <div className="flex flex-col items-center justify-center p-0 md:px-24 md:py-12">
        <div className="mt-12">
          <HeaderText header="About Xenophobia" />
        </div>

        <div className="flex flex-col mt-12 items-center justify-center w-full md:md:w-[60%]">
          <div className="flex flex-col md:flex-row  gap-4 items-center justify-center w-full">
            <LargeImage
              src={"/images/about-left.jpg"}
              width="700px"
              height="400px"
            />
            <LargeImage
              src={"/images/about-right.jpg"}
              width="200px"
              height="400px"
            />
          </div>
          <DescriptionText
            text="Xenophobia is a pervasive problem that exists in many societies today. It is fear and hatred of people from other countries and cultures that often lead to discrimination, prejudice and racism. Xenophobia can take many forms, including hateful language and acts of violence against individuals and groups."
            noBackground
            padding="px-12 py-8"
          />
          <DescriptionText
            text="Xenophobia has surged around the world in recent years due to economic instability, political tensions, social media and other factors. Xenophobic attitudes and behaviors often target immigrants and refugees who are irrationally seen as a threat to local jobs and culture. Such attitudes can lead to exclusion and marginalization and make it difficult for individuals and communities to fully integrate into society. To ensure a more inclusive and just society for all, it is critical to address and combat xenophobia through education, awareness raising and policy change."
            padding="px-12 py-0"
            noBackground
          />
        </div>
      </div>
      <div className="bg-xeno-lightyellow mt-12">
        <div className="flex flex-col items-center justify-center md:px-24 md:py-12">
          <HeaderText header="Xenophobia on Twitter" />
          <div className="flex flex-col mt-12 items-center md:w-[60%] w-full">
            <DescriptionText
              text="Xenophobic statements on Twitter are a common form of hate speech and have become an increasing problem in recent years. Such statements express fear and hatred towards people of other countries and cultures and may lead to discrimination, prejudice and racism."
              noBackground
              padding="px-12 py-0"
            />
            <DescriptionText
              text="Xenophobic statements on Twitter are often directed at individuals or groups based on their national origin, religion, or ethnicity. We focus on discourse by public figures and entities such as journalists, government agencies, and non-profit organizations with verified Twitter accounts. Our purpose is to highlight the accountability of public actors bearing legal or moral responsibility for their speech. By encouraging respectful discourse and combating hateful rhetoric, we can build a more harmonious society, both online and offline."
              noBackground
              padding="px-12 py-8"
            />
            <LargeImage
              src={"/images/twitter-xeno.jpg"}
              className="w-full md:w-[90%]"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center md:px-24 md:py-12">
        <HeaderText header="Objectives" />
        <div className="flex flex-col mt-12 items-center md:w-[60%] w-full">
          <DescriptionText
            text="The Xenophobia Meter Project (XMP) is a modest response to the problem of ever-increasing anti-immigrant hate speech on the internet and governments' repeated refusal to govern it effectively. We believe that identifying and exposing xenophobic speech will increase public understanding of the problem and show allyship to immigrant communities who endure xenophobic hate."
            noBackground
          />
        </div>
        <div className="flex justify-center">
          <YoutubeVideo videoId={"XzXrUTw0GIY"} />
        </div>
      </div>
      <div className="bg-xeno-lightyellow mb-24">
        <div className="flex flex-col items-center justify-center md:px-24 py-12">
          <HeaderText header="Our Mission" />
          <div className="flex flex-col mt-12 items-left md:w-[60%] w-full">
            <DescriptionText
              text="Our team constructed a dataset of Twitter data collected from a range of U.S.-based verified accounts, including government agencies, media, and NGOs, labeled with 7-levels of sentiment toward foreigners, from very xenophobic to very anti-xenophobic. These Twitter accounts are on the forefront of defining the rhetoric in relation to immigration and policy. This publicly-available dataset will be useful for developing  Machine Learning (ML) models to detect what constitutes xenophobic hate speech. "
              noBackground
              padding="px-12 py-0"
            />
            <DescriptionText
              padding="px-12 py-6"
              text="
              Labeling the dataset is completed by trained human labelers. Learn more about the labeling procedures and experience on the labeling page."
              noBackground
            />
            <DescriptionText
              text="
              This project originated at Cornell University. For more information about our team, visit the team page.
              "
              padding="px-12 py-0"
              noBackground
            />
          </div>
        </div>
      </div>
    </div>
  );
}
