import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function XMPButton(props) {

  const navigate = useNavigate();

  return (
    <button className='bg-xeno-yellow px-4 py-2 rounded-full font-bold' onClick={() => navigate(props.redirect)} ><span className='flex'>{props.text} <box-icon name='right-arrow-alt'></box-icon></span></button>
  )
}
