import { useEffect, useState } from "react"
import * as d3 from 'd3'
import HeaderText from "../HeaderText"
import DescriptionText from "../DescriptionText"

const TopContributors = ({accounts}) => {

  const [data, setData] = useState([])
  const refreshData = () => {
    // sort by top rating
    let newData = [...accounts].sort((a, b) => a["num_xenophobic_tweets"] < b["num_xenophobic_tweets"])
    // only take the top 5
    setData(newData.slice(0, 5))
  }
  useEffect(() => refreshData(), [accounts])

  useEffect(() => {
    const width = 900
    const height = 650
    const padding = 50
    const paddingLeft = 50

    // calculate vis params
    const barInterval = (width / (data.length + 1))
    const barWidth = barInterval * 0.75 
    const minXenophobicTweets = Math.min(...data.map(d => d["num_xenophobic_tweets"]))
    const maxXenophobicTweets = Math.max(...data.map(d => d["num_xenophobic_tweets"]))
  
    const svg = d3.select("#topContributorsVis")
      .attr("width", width)
      .attr("height", height)

    // define scale for color of account based on type
    const colorScale = d3.scaleLinear()
      .domain([1, 2, 3, 4])
      .range(["lightblue", "orange", "purple", "pink"])
    // define y scale for num of xenophobic tweets
    const xenophobiaScale = d3.scaleLinear()
      .domain([minXenophobicTweets, maxXenophobicTweets])
      .range([height - padding, padding + barWidth])

    // clear previous visualization
    svg.selectAll("*").remove()

    // create manual axis labels
    svg.append("text")
        .attr("width", height)
        .attr("y", "1em")
        .attr("x", -height / 2)
        .attr("text-anchor", "middle")
        .attr("transform", "rotate(-90)")
        .text("Number of xenophobic tweets (rating < 0)")
    svg.append("line")
      .attr("x1", paddingLeft)
      .attr("y1", 0)
      .attr("x2", paddingLeft)
      .attr("y2", height - padding)
      .attr("stroke", "black")
    svg.append("line")
      .attr("x1", paddingLeft)
      .attr("y1", height - padding)
      .attr("x2", width)
      .attr("y2", height - padding)
      .attr("stroke", "black")

    // map accounts to rects
    svg.selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d, i) => paddingLeft + ((i + 0.25) * barInterval))
      .attr("y", (d) => xenophobiaScale(d["num_xenophobic_tweets"]))
      .attr("width", barWidth)
      .attr("height", (d) => height - padding - (xenophobiaScale(d["num_xenophobic_tweets"])))
      .attr("fill", (d) => colorScale(d["account_type_id"]))
    // append account images atop bars
    svg.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
        .attr("cx", (d, i) => paddingLeft + ((i + 0.25) * barInterval) + barWidth / 2)
        .attr("cy", (d) => xenophobiaScale(d["num_xenophobic_tweets"]) - barWidth / 2)
        .attr("r", barWidth / 2)
        .attr("fill", "none")
        .attr("stroke", (d) => colorScale(d["account_type_id"]))
        .attr("stroke-width", 5)
    svg.selectAll("image")
      .data(data)
      .enter()
      .append("image")
        .attr("x", (d, i) => paddingLeft + ((i + 0.25) * barInterval))
        .attr("y", (d) => xenophobiaScale(d["num_xenophobic_tweets"]) - barWidth)
        .attr("href", (d) => `/images/account-images/${d["id"]}.png`)
        .attr("width", barWidth)
        .attr("clip-path", "inset(0% round 50%)")
        
    svg.exit().remove()
  }, [data])

  return <div 
    id="tcVisSection"
    style={{
      margin: '1rem auto',
      textAlign: 'left',
      maxWidth: '100%',
    }}
    className="max-xl:p-4"
    >
    <h2 className="text-3xl my-4">Who are the top 5 contributors of xenophobic content?</h2>
    <p className="my-2">Discover who is behind most of the anti-foreigner speech on select Twitter accounts and take steps to call them out.</p>
    <div className="flex max-xl:flex-col max-xl:overflow-x-scroll">
      <svg id='topContributorsVis' style={{ overflow: "scroll", flexGrow: "1", minHeight: "400px"}}></svg>
      <aside className='p-4'>
        <p className="mt-6 font-bold">Type of Account:</p>
        <ul style={{listStyle: "none"}}>
          <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "orange"}}></i> Media</li>
          <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "purple"}}></i> Non-Profit</li>
          <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "lightblue"}}></i> Governmental</li>
          {/* <li> <i className="inline-block w-6 h-6 mx-2 rounded-xl mt-3 translate-y-1.5" style={{backgroundColor: "pink"}}></i> Other</li> */}
        </ul>
      </aside>
    </div>
  </div>
}

export default TopContributors