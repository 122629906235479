import React from 'react'
import { useState, useEffect } from 'react'
import Banner from '../components/Banner'
import HeaderText from '../components/HeaderText'
import BlockQuote from '../components/BlockQuote'
import DescriptionText from '../components/DescriptionText'
import LargeImage from '../components/LargeImage'
import TextContentWithHeader from '../components/TextContentWithHeader'
import PublicResponse from '../components/visualizations/PublicResponse'
import XenophobiaByType from '../components/visualizations/XenophobiaByType'
import XMPButton from '../components/XMPButton'
import TopContributors from '../components/visualizations/TopContributors'


export default function DataPage() {
  // fetch tweets data

  const generateDownloadLink = () => {
    const publicUrl = process.env.PUBLIC_URL;
    return `${publicUrl}/data-distribution/xmp_data.zip`;
  };

  const downloadLink = generateDownloadLink();

  const numTweets = 10000
  const [tweets, setTweets] = useState([])
  // fetch accounts data
  const [accounts, setAccounts] = useState([])
  useEffect(() => {
    if (accounts.length === 0) {
      fetch(process.env.REACT_APP_API_URL + `/db/get/accounts?num=${numTweets}`)
        // fetch(`http://info5900.infosci.cornell.edu:8080/db/get/accounts`)
        .then((value) => {
          return value.json()
        })
        .then((fetchedData) => {
          fetchedData.sort((a, b) => a['id'] > b['id'])
          setAccounts(fetchedData)
        })
    }
    if (tweets.length === 0) {
      fetch(process.env.REACT_APP_API_URL + `/db/get/tweets?num=10000&${numTweets}`)
        // fetch(`http://info5900.infosci.cornell.edu:8080/db/get/tweets?num=10000&${numTweets}`)
        .then((value) => {
          return value.json()
        })
        .then((fetchedData) => {
          setTweets(fetchedData)
        })
    }

  }, [accounts.length, tweets.length])

  return (
    <div className=''>
      <Banner header="Data" text="Learn more about what we discovered through our data " />
      <div className='flex flex-col items-center md:px-24 md:py-12'>
        <div className='text-left max-w-6xl max-xl:p-4 my-12 mt-24'>
          <HeaderText header="Xenophobic Accounts on Twitter" />
          <p className='my-12'>The visualization below aims to highlight which Twitter accounts, from our dataset, our responsible for posting the most xenophobic content. The bars have been colored according to the type of account, ie. media, non-profit, etc. Below we see that @FAIRImmigration takes the lead for the most xenophobic rated tweets. We hope this visualization can help these actors reflect on their actions.</p>
        </div>
        <div className='flex justify-between max-lg:w-full'>
          <TopContributors accounts={accounts} />
        </div>
      </div>
      <div className='flex flex-col items-center md:px-24 md:py-12 bg-xeno-lightyellow'>
        <div className='text-left max-w-6xl max-xl:p-4 my-12 mt-24'>
          <HeaderText header="Ditribution of Xenophobic Accounts on Twitter" />
          <p className='my-12'>The visualization shows the overall sentiment towards foreigners seen through the sample of tweets we looked at. Accounts are placed on a scale rated from anti-foreigner to pro-foreigner, and the bubbles are sized according to the number of tweets we labeled from each account.</p>
        </div>
        <div className='flex justify-between w-full bg-white'>
          <XenophobiaByType accounts={accounts} />
        </div>
      </div>
      <div className='flex flex-col items-center md:px-24 md:py-12'>
        <div className='text-left max-w-6xl max-xl:p-4 my-12 mt-24'>
          <HeaderText header="Public Response to Xenophobic Content" />
          <p className='my-12'>The visualization shows the number of likes that different tweets got from Twitter users. Each bubble is sized according to the number of likes it received. We hope that this can help people reflect on the kind of content the engage with, and what kind of harmful content they might be promoting.</p>
        </div>
        <div className='flex justify-between w-full'>
          <PublicResponse tweets={tweets} accounts={accounts} />
        </div>
      </div>
      <div className='flex flex-col items-center md:px-24 md:py-12'>
        <div className='text-left max-w-5xl max-xl:p-4'>
          <HeaderText header="Get The Dataset" />
          <p className='my-5'>The dataset is our application of the Xenophobia Meter to a Twitter dataset collected in 2020. We collected tweets from 11 verified accounts. These accounts represent entities and organizations that are at the forefront of mainstream media rhetoric on topics related to U.S. immigration. The dataset contains tweets across 11 accounts, each labeled by at least 3 human labelers. he dataset is freely available to the public in order to open our process to comment and feedback, and to promote awareness, research, and advocacy for a more inclusive society.</p>
          <p className='my-5'>Learn more about the data collection process and effort on the Meter and Labeling pages.</p>
          <div className='flex justify-center'>
            <a href={downloadLink} download={`xmp_data.zip`}>
              <XMPButton text="Download Data" />
            </a>
          </div>
        </div>
      </div>
    </div >
  )
}
