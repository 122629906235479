import React from 'react'
import DescriptionText from './DescriptionText'
import HeaderText from './HeaderText'
import XMPButton from './XMPButton'

export default function Banner(props) {
  return (
    <div className='relative h-[400px] border-b-2 bg-xeno-yellow' style={{background:"url('/banner.png')",backgroundSize:'cover',backgroundPosition:'50% 60%'}}>
      <div className='w-full h-full flex items-center justify-center p-4 md:p-24 '>
        <div className='flex flex-col items-center '>
          <h1 className='font-bold' style={{fontSize:'3em'}}>{props.header}</h1>
          <h1 className=' font-bold text-2xl mt-8'>{props.text}</h1>

        </div>
      </div>
    </div>
  )
}
