import React from 'react';

function VerticalBlockQuote(props) {
    return (
        <div className={`${props.fullWidth ? 'w-full h-auto md:w-[200px] ' : 'w-[200px]' }  bg-slate-200 p-4 rounded-md h-full flex `}>
            <div className='flex flex-col items-center justify-between bg-white p-4 rounded-md md:min-h-[375px]'>
                <p className='text-md text-left'> 
                    {props.quoteText}
                </p>
                <p className='font-bold text-right'>
                    {!props.author ? "Anonymous labeler" : props.author}
                </p>
            </div>
        </div>
    );
}

export default VerticalBlockQuote;