import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const tabData = [
  {
    name: "Use our Meter",
    path: "/usage",
  },
  {
    name: "Labeling",
    path: "/label",
  },
  {
    name: "Team",
    path: "/team",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Data",
    path: "/data",
  },
];

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, selectedTabSet] = useState(tabData.findIndex((tab) => tab.path === location.pathname));
  const [navbarStatus,setNavbarStatus] = useState(0)

  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 bg-white h-[82px] w-screen flex items-center px-2 justify-between drop-shadow-sm border-b-2 md:border-0"
        style={{ zIndex: 100 }}
      >
        <div className="cursor-pointer">
          <img src="logo.svg" alt="logo" className="pl-2 md:px-0 h-[30px]" onClick={() => {
                  selectedTabSet(-1)
                  navigate('/');
                }} />
        </div>
        <div className="items-center flex-row-reverse hidden md:flex">
          {tabData.map((tab, index) => {
            return (
              <p
                className={`mr-3 ${selectedTab === index ? "font-bold" : ""
                  } p-2 rounded-md cursor-pointer`}
                onClick={() => {
                  
                  navigate(tab.path);
                  selectedTabSet(index);
                }}
              >
                {tab.name}
              </p>
            );
          })}
        </div>
        <div className="mt-2 md:hidden">
          {navbarStatus === 0 ? <box-icon name='menu' size="lg" onClick={()=>setNavbarStatus(1)}></box-icon> : <box-icon name='x' size="lg" onClick={()=>setNavbarStatus(0)}></box-icon>}
        </div>
        {navbarStatus ? <div className="fixed top-[82px] left-0 bottom-0 h-screen w-[60%] flex flex-col bg-white border-r-2" style={{ zIndex: 2 }}>
          {tabData.map((tab, index) => {
            return (
              <p

                className={` ${selectedTab === index ? "font-bold bg-xeno-lightyellow" : ""
                  } p-8 py-4 cursor-pointer text-left text-xl border-b-2`}
                onClick={() => {
                  navigate(tab.path);
                  selectedTabSet(index);
                  setNavbarStatus(0);
                }}
              >
                {tab.name}
              </p>
            );
          })}
        </div> : <></>}
      </div>
      <div className="h-[82px]" />
    </>
  );
}
