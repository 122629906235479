import React from 'react'
import DescriptionText from './DescriptionText'
import HeaderText from './HeaderText'

export default function TextContentWithHeader(props) {
    return (
        <div className='flex flex-col items-start my-8'>
            <HeaderText header={props.header} textLeft={props.textLeft} textRight={props.textRight} />
            <br />
            <div className='w-full flex justify-center'>
                <DescriptionText padding={props.padding} text={props.text} noBackground={props.noBackground} textLeft={props.textLeft} textRight={props.textRight} />
            </div>
        </div>
    )
}
