import React from "react";
import Banner from "../components/Banner";
import BlockQuote from "../components/BlockQuote";
import DescriptionText from "../components/DescriptionText";
import LabellerCard from "../components/LabellerCard";
import LargeImage from "../components/LargeImage";
import TextContentWithHeader from "../components/TextContentWithHeader";
import HeaderText from "../components/HeaderText";
import VerticalBlockQuote from "../components/VerticalBlockQuote";

export default function Labelling() {
  return (
    <div>
      <Banner
        text="“I knew it was an issue that I wanted to get involved in combating.”"
        header="The Labeling Process"
      />
      <div className="flex flex-col items-center lg:px-24 lg:md:py-12">
        <TextContentWithHeader
          header="Recruitment and training"
          text="Labelers are recruited among students at Cornell university. They go through rigorous training, in which they learn about U.S. immigration and foreign policies, current events that shape public speech, and learn about the meter and how to use it when labeling tweets."
          noBackground
        />
        <div className="p-8 md:p-0">
          <BlockQuote quoteText="I would say I tried to force myself into that mindset by saying, I am doing this for the social good." />
        </div>
      </div>
      <div className="bg-xeno-lightyellow md:py-12">
        <div className="flex flex-col items-center justify-center md:px-24 py-12">
          <HeaderText header="Labeling procedures" />
          <div className="flex flex-col md:mt-12 items-center w-full md:w-[70%]">
            <DescriptionText
              text="Each labeler receives a set of tweets to label for the upcoming week. The set is taken from the 11 verified accounts in the dataset, ensuring that each tweet has at least 3 labelers. To label a tweet, the labeler reads its content and reviews any additional media and thumbnails that accompany it. Based on these, the labeler identifies if the tweet is relevant to foreigners, and then decides which of the 7 levels of the Xenophobia Meter to apply, and indicates the rationale for their chosen rating."
              noBackground
              padding="px-12 py-8"
            />
            <DescriptionText
              text="Once a week, labelers reconvene to discuss cases where multiple labelers differed on their ratings. In the meeting, they discuss U.S. policies, nuanced language use, and possible political motives related to a tweet and its language. The discussion is documented, and after the discussion, a labeler may change their rating. This process is called calibration."
              noBackground
              padding="px-12 py-0"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center md:px-24 md:md:py-12">
        <div className="mt-12 md:mt-0">
          <HeaderText header="Challenges, strategies, and growth" />
        </div>
        <div className="flex flex-col mt-12 items-center w-[80%]">
          <div className="my-8">
            <DescriptionText
              header="Individual Inconsistencies"
              text="Labelers are college students with different past experiences, background knowledge, and political opinions. This may result in inconsistencies in labeling despite the implementation of training sessions, labeling guidelines, multiple labelers for each tweet, and calibration meetings.  We recognize that identifying one correct ground truth for xenophobic expressions may be challenging, as labelers are human with opinions and beliefs."
            />
          </div>
          <div className="my-8">
            <DescriptionText
              header="Emotional Impact"
              text="Exposure to negative xenophobic content could emotionally impact labelers. To address this, labelers are provided with a mix of tweets from positive and negative accounts, and discuss emotional impacts during calibration meetings."
            />
          </div>
          <div className="my-8">
            <DescriptionText
              header="Growth Throughout The Labeling Journey"
              text="Overtime, labelers become more experienced in labeling, as they learn about xenophobia as a concept, xenophobic and anti-xenophobic language and policies, current events and politics. They learn to easily handle more nuanced tweets. They gain new insights and appreciate diverse perspectives, broadening their horizons and encouraging them to think differently as human beings."
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center  md:md:py-12 md:mb-24">
        <div className="flex flex-col md:flex-row justify-between items-stretch w-full md:w-[40%] gap-8 p-12 md:p-0 ">
          <VerticalBlockQuote
            fullWidth
            quoteText="“It definitely opened my eyes to different opinions...I always think that is very valuable.”"
          />
          <VerticalBlockQuote
            fullWidth
            quoteText="“In addition to being a practical good, I also thought labeling was really, like an educational good.”"
          />
          <VerticalBlockQuote
            fullWidth
            quoteText="“I learned from the training session that I have to change my mind set to probably not the be a machine, but be a person.”"
          />
        </div>
      </div>
    </div>
  );
}
